@import "reset";
@import "colors";

body {
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $color-base;
    font-size: 16px;
    background-color: $color-base-bg;
}

.main-content {
    min-height: 100vh !important;
}

.content {
    padding: 25px;
}

.dashboard {
    background-color: $color-content-bg;
    width: 100%;
    min-height: 100vh;
}

.accent {
    color: $color-accent;
}

.slogan {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-style: italic;
    line-height: 1.47;
    letter-spacing: 0.57px;
    text-align: left;
    color: $color-alter;
}

.wh-form {
    padding: 44px 42px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.53px;
    text-align: left;
    min-height: calc(100vh - 240px);

    h3 {
        font-size: 20px;
    }

    label {
        font-weight: 600;
    }
}

.input {
    background: $color-input-bg;
}

.wh-button {
    background: $color-button-bg !important;
    color: $color-button !important;
    text-transform: uppercase !important;
    text-decoration: none;
    font-weight: 400 !important;
    cursor: pointer;
    border-radius: 2px !important;
    padding: 3px 5px;

    &:hover {
        background: $color-button-hover-bg;

        a {
            text-decoration: none;
        }
    }

    &:disabled {
        background: #000000 !important;
        cursor: not-allowed !important;
    }
}

.wh-selected-button {
    background: $color-button-selected-bg !important;
    color: $color-button-selected !important;
    text-transform: uppercase !important;
    text-decoration: none;
    font-weight: 400 !important;

    &:hover {
        background: $color-button-hover-bg;
        color: $color-button-hover-bg;
    }
}

.hidden {
    display: none;
}

.pointer-link {
    cursor: pointer;
  }


/* --------- TOPOGRAPHY -----------*/

h1, h2, h3, h4, h5, h6 {
    color: $color-accent;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: Roboto, serif;
}

.text-bold {
    font-weight: 600;
}

.text-medium {
    font-weight: 500;
}

.text-light-medium {
     font-weight: 400;
}

.text-accent {
    color: $color-accent;
}

.text-accent-secondary {
    color: $color-accent-secondary;
}

.divider {
    color: #FFF !important;
    background: #FFF !important;
    margin-top: 2rem !important;
}

.divider-dark {
    color: $color-divider !important;
    background: $color-divider !important;
    margin-top: 2rem !important;
}

.auth-font {
    font-family: Roboto, serif;
    line-height: 1.79;
    font-weight: bold;
}

.primary-font {
    font-family: 'Roboto', sans-serif;
}

.secondary-font {
    font-family: "Palatino Normal-Italic", sans-serif;
}

.size-0_75 {
    font-size: 0.75rem !important;
}

.regular-size {
    font-size: 1rem !important;
}

.size-1_25 {
    font-size: 1.25rem !important;
}

.size-1_5 {
    font-size: 1.5rem !important;
}

.size-2 {
    font-size: 2rem !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.compact-text {
    text-overflow: ellipsis;
    width: 460px;
    height: 1.2em;
    white-space: nowrap;
}

.page-a4 {
    background-color: white !important;
    display: block;
    width: calc(21 * 58px);
    height: calc(27.1761 * 58px);
    padding: 0 !important;
    font-size: 16px;
    line-height: 1.9rem;
}

.normal-line-height {
    line-height: normal
}

.flat-scroll::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f9f9fb;
}

.flat-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f9f9fb;
}

.flat-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #d4d8de;
}

footer {
    .footer-content {

        ul {
            @media (min-width: 768px) {
                li {
                    padding-left: 10px;
                    display: inline-block;

                    &:before {
                        padding-right: 10px;
                        content: "|";
                    }
                }

                .start-item {
                    & li {
                        padding-left: 5px;
                        display: inline-block;
                    }

                    &:before {
                        padding-right: 5px;
                        content: "";
                    }
                }
            }
        }
    }
}


.scrollable::-webkit-scrollbar-track
{
    border-radius: 3px;
    background-color: #f9f9fb;
}

.scrollable::-webkit-scrollbar
{
    width: 6px;
    background-color: #f9f9fb;
}

.scrollable::-webkit-scrollbar-thumb
{
    border-radius: 3px;
    background-color: #d4d8de;
}