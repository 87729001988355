$color-base: #373737;
$color-accent: #537b98;
$color-alter: #000000;
$color-base-bg: #f2f2f2;
$color-content-bg: #f8f8f8;
$color-form-bg: #ffffffcc;
$color-button: #f1f1f1;
$color-button-bg: #1f72a2;
$color-button-hover-bg: #7a885b;
$color-button-selected: #1f72a2;
$color-button-selected-bg: #e7eae7;
$color-divider: #cfd3c7;
$color-danger-red: #f00;
$color-input-bg: #FFF;
$color-disabled-bg: #dddada;
$color-disabled: #aaaaaa;
$color-pastel-khaki: #bbd4e3;
$color-accent-secondary: #bbd4e3;
$color-pastel-khaki-light: #eceeea;
$color-report-accent: #626d4b;
$color-report-exercise: #537b98;
$color-report-sleep: #1a4663;
$color-report-neuro: #aab498;
$color-report-microbiome: #8da1af;
$color-report-chronic: #949f87;
$color-report-longevity: #4e86ac;
$color-report-overall: #406b82;
$color-report-details: #8ba551;
